import { combineReducers } from "@reduxjs/toolkit";
import auth from "../authSlice";
import app from "../appSlice";
import subjects from "../subjectSlice";
import courses from "../courseSlice";

const rootReducer = combineReducers({
	auth,
	app,
	subjects,

	courses,
});

export default rootReducer;
