import ExpirySession from '../utils/expirySession'
import ApiInstance from './apiInstance'
import AutInstance from './authInstance'

const signup = async (data) => {
	const response = await ApiInstance.post("/admin/register", data);
	// if (response.data) {
	// 	ExpirySession.set("user", response.data);
	// }
	return response;
};;

const login = async (data) => {
	const response = await ApiInstance.post("admin/login", data);
	if (response?.data) {
		console.log(response, "response");
		ExpirySession.set("user", response.data);
	}
	return response.data;
};

const resetPassword = async (data) => {
	const response = await ApiInstance.post("/admin/reset-password", data);
	return response.data;
};

const sendOTP = async (data) => {
	const response = await ApiInstance.post(
		"/admin/send-password-resetotp",
		data
	);
	return response.data;
};

// Auth endpoints
const logout = () => {
  AutInstance.post(`admin/logout`)
  ExpirySession.clearAll()
}

const changePassword = async (data) => {
  const response = await AutInstance.post(`/admin/change/password`, data)
  return response.data
}

export const authService = {
  signup,
  login,
  resetPassword,
  sendOTP,

  logout,
  changePassword,
}
