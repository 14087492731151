import axios from 'axios'

const instance = axios.create({
	baseURL: "https://sgcoba.ng",
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
});


export default instance
